import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  NativeSelect,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { useSelector, useDispatch } from '../../../../redux/store';

import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';
import { UploadSingleFile } from '../../../../components/upload';
import { openTransactionsDialog } from '../../../../redux/slices/transactionsDialog';
import { fCurrency, fNumberCommaSeparated } from '../../../../utils/formatNumber';
import Iconify from '../../../../components/Iconify';

// Drawer
import ConfirmDepositDrawer from './ConfirmDepositDrawer';
import CustomModal from '../../../../components/CustomModal';
import Image from '../../../../components/Image';

import palette from '../../../../theme/palette';
import PopupQrCode from './PopupQrCode';
import { StyledInput, ThemeButton } from '../../../GlobalStyles';

const TypographyCustom = styled(Typography)({
  color: palette.transactionDialog.deposit.inputLabel,
  fontSize: '14px',
  fontWeight: 500,
});

const SmallText = styled(Typography)(({ theme }) => ({
  color: palette.transactionDialog.deposit.smallText,
  fontSize: '13px',
  fontWeight: 300,
}));

const SmallTextBold = styled('span')(({ theme }) => ({
  color: palette.transactionDialog.deposit.smallText,
  fontSize: '13px',
  fontWeight: 600,
}));

const ExtraText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 500,
  color: '#fff',
}));

const LargeBlackText = styled(Typography)(({ theme }) => ({
  color: palette.transactionDialog.deposit.largeText,
  fontSize: '20px',
  fontWeight: 700,
  textAlign: 'center',
}));

const SuggestedTopupBox = styled(Box)(({ theme, isActive }) => ({
  backgroundColor: palette.transactionDialog.deposit.suggestedTopupBg,
  border: isActive ? '3px solid #D9D0F1' : '2.9px solid transparent',
  padding: '16px 4px 11px',
  borderRadius: '6px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  // borderWidth: '3px',
  // borderStyle: 'solid',
}));

const SuggestedTopupTxt = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  wordBreak: 'break-all',
  fontSize: '16px',
  color: palette.transactionDialog.deposit.suggestedTopupTxt,
}));

const SmallPromoBox = styled(Box)(({ isActive, theme }) => ({
  background: isActive
    ? palette.transactionDialog.deposit.smallPromoBox.active
    : palette.transactionDialog.deposit.smallPromoBox.bg,
  fontSize: '10px',
  fontWeight: 700,
  height: '16px',
  padding: '0 4px',
  right: '-5px',
  top: '-4px',
  transform: 'scale(.8)',
  position: 'absolute',
  zIndex: 10,
  color: palette.transactionDialog.deposit.smallPromoBox.color,
  borderBottomLeftRadius: '5px',
  borderTopRightRadius: '5px',
}));

const BonusBox = styled(Grid)(({ theme }) => ({
  color: palette.transactionDialog.deposit.bonusBox.color,
  background: palette.transactionDialog.deposit.bonusBox.bg,
  padding: ' 5px 15px',
  fontSize: '14px',
  marginTop: '20px',
  fontWeight: 700,
  borderRadius: '10px',
  border: `3px solid ${palette.transactionDialog.deposit.bonusBox.border}`,
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
}));

const StyledSelect = styled(NativeSelect)(({ theme }) => ({
  width: '100%',
  color: palette.transactionDialog.deposit.select.color,
  fontSize: '14px',
  fontWeight: 600,
  backgroundColor: palette.transactionDialog.deposit.select.bg,
  padding: '4px 11px',
  border: `1px solid ${palette.transactionDialog.deposit.select.border}`,
  borderRadius: '5px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
}));

const SelectedBankBox = styled(Box)(({ theme }) => ({
  background: palette.transactionDialog.deposit.selectedBankBox.bg,
  // border: `1px solid ${palette.transactionDialog.deposit.selectedBankBox.border}`,
  padding: '15px',
  fontSize: '14px',
  marginTop: 8,
  fontWeight: 700,
  borderRadius: '10px',
}));

const PayMethodWrapper = styled(Box)(({ theme, isActive }) => ({
  backgroundColor: '#1F0059',
  border: `${isActive ? '2px solid #D9D0F1' : '1px solid #9B85D7'}`,
  boxShadow: isActive ? palette.button.shadow : 'unset',
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

const DepositInputBox = styled(Stack)(({ theme, hasData }) => ({
  background: palette.transactionDialog.deposit.depositInputBox.bg,
  border: `3px solid ${palette.transactionDialog.deposit.depositInputBox.border}`,
  borderRadius: hasData ? '6px 6px 0 0' : '6px',
  padding: '4px 0',
  alignItems: 'center',
  marginBottom: hasData ? 0 : '20px',
}));

const DepositDivider = styled(Divider)({
  border: `1px solid #fff`,
});

const DepositInput = styled(InputBase)(({ theme }) => ({
  color: palette.transactionDialog.deposit.depositInput.color,
  flexGrow: 1,
  fontSize: '20px',
  fontWeight: 700,
  padding: 0,
  input: {
    textAlign: 'center',
    padding: '0 10px 0 20px',
  },
}));

const BankRefNumber = styled(InputBase)(({ theme }) => ({
  flexGrow: 1,
  fontSize: '1rem',
  fontWeight: 600,
  borderRadius: '6px',
  padding: '4px 11px',
  background: '#000',
  width: '100%',
  '& input': {
    color: `#fff !important`,
    fontWeight: 600,
    '&::placeholder': {
      color: `#8f8f8f !important`,
    },
  },
}));

const ConfirmButtonContainer = styled(Grid)(({ theme }) => ({
  // padding: '8px 12px',
  position: 'relative',
  background: 'none',
  boxShadow: 'none',
  borderRadius: '10px',
  marginTop: '10px',
  cursor: 'pointer',
}));

const DepositBtnText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  color: palette.transactionDialog.deposit.bepositBtnText,
  fontWeight: 700,
  // textShadow: '0px 1px 4px #000',
}));

const DepositBtnAmount = styled(Typography)(({ theme }) => ({
  fontSize: '26px',
  color: palette.transactionDialog.deposit.depositAmt,
  fontWeight: 700,
  marginRight: 2,
  wordBreak: 'break-all',
  // textShadow: '0px 1px 4px #000',
}));

const InitAccountTextBox = {
  name: '',
  bankNumber: '',
};

const Deposit = () => {
  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { memberBalance } = useSelector((x) => x.lookup);

  const [depositAmount, setDepositAmount] = useState(100);

  const [confirmDeposit, setConfirmDeposit] = useState(false);

  const [currencyTopup, setCurrencyTopup] = useState([]);

  const [currency, setCurrency] = useState('');

  const [bankTypeName, setBankTypeName] = useState('');

  const [minimumDeposit, setMinimumDeposit] = useState('');

  const [maximumDeposit, setMaximumDeposit] = useState('');

  const [paymentMethods, setPaymentMethods] = useState({});

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  const [bankList, setBankList] = useState();

  const [selectedBank, setSelectedBank] = useState(null);

  const [errors, setErrors] = useState({});

  const [bankSlip, setBankSlip] = useState(null);

  const [promotions, setPromotions] = useState(null);

  const [selectedPromotion, setSelectedPromotion] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showBankQrCode, setShowBankQrCode] = useState(null);

  const [selectedBankSource, setSelectedBankSource] = useState('');

  const [bankSourceList, setBankSourceList] = useState({});

  // for selected deposit custom textbox
  const [accountHolder, setAccountHolder] = useState(InitAccountTextBox);

  const [showAlert, setShowAlert] = useState({
    show: false,
    message: '',
  });

  const [referenceNumber, setReferenceNumber] = useState('');

  const [topPayQrCode, setTopPayQrCode] = useState(null);

  const [isAutoUpdatePromotion, setIsAutoUpdatePromotion] = useState(false);

  const [overrideCurrencyTopup, setOverrideCurrencyTopup] = useState([]);

  // const [amountInThousandths, setAmountInThousandths] = useState(false);

  const selectedBankData = useMemo(() => {
    if (bankList?.length && selectedBank) {
      return bankList.find((f) => f.BankAccount.id === selectedBank) || {};
    }

    return {};
  }, [bankList, selectedBank]);

  const selectedHasCustomInput = useMemo(() => {
    if (
      selectedBankData?.deposit_source_bank_account_holder_name ||
      selectedBankData?.deposit_source_bank_account_number
    )
      return true;

    return false;
  }, [selectedBankData]);

  useEffect(() => {
    getDepositMethods();
  }, [memberBalance]);

  // useEffect(() => {
  //   if (memberBalance?.currencyCode.toUpperCase() === 'MMK' || memberBalance?.currencyCode.toUpperCase() === 'VND') {
  //     setAmountInThousandths(true);
  //   }
  // }, [memberBalance]);

  useEffect(() => {
    if (selectedPaymentMethod && Object.keys(paymentMethods)?.length > 0) {
      setBankList(paymentMethods?.[selectedPaymentMethod]?.Bank);
      setSelectedBank(paymentMethods?.[selectedPaymentMethod]?.Bank[0]?.BankAccount?.id);
      setAccountHolder(InitAccountTextBox);
      const bankSourceCodes = paymentMethods?.[selectedPaymentMethod]?.Bank[0]?.deposit_source_bank_codes;
      if (bankSourceCodes && Object.keys(bankSourceCodes)?.length) {
        setBankSourceList(bankSourceCodes);
        setSelectedBankSource(Object.keys(bankSourceCodes)[0]);
      } else {
        setBankSourceList({});
        setSelectedBankSource('');
      }
    }
  }, [paymentMethods, selectedPaymentMethod]);

  useEffect(() => {
    if (bankList) {
      const _selectedBank = bankList.find((f) => f.BankAccount?.id === selectedBank);
      if (_selectedBank) {
        const { min_deposit: minDeposit, max_deposit: maxDeposit } = _selectedBank?.BankAccount;

        /**
         * set bank/promotion minimum deposit as default
         * whichever is greater
         *  */
        const _selectedPromotion = promotions?.find((f) => f.id === selectedPromotion);

        const promoMaxDeposit = _selectedPromotion?.max_deposit || 0;

        const _maxDeposit = maxDeposit || 0;

        const _minDeposit = minDeposit || 0;

        const sanitizedPromoMaxDeposit = parseFloat(promoMaxDeposit.toString().replace(/[^0-9.]/g));
        const sanitizedBankMaxDeposit = parseFloat(_maxDeposit.toString().replace(/[^0-9.]/g));

        const maxDepositValue =
          sanitizedBankMaxDeposit <= sanitizedPromoMaxDeposit ? sanitizedBankMaxDeposit : sanitizedPromoMaxDeposit;

        setMinimumDeposit(
          // amountInThousandths
          //   ? parseFloat(minDeposit.replace(/[^0-9.]/g, '')) * 1000 :
          parseFloat(_minDeposit.toString().replace(/[^0-9.]/g, ''))
        );
        setMaximumDeposit(
          // amountInThousandths
          //   ? parseFloat(maxDeposit.replace(/[^0-9.]/g, '')) * 1000 :
          parseFloat(maxDepositValue)
        );

        const promoMinDeposit = _selectedPromotion?.min_deposit || 0;

        const sanitizedPromoMinDeposit = parseFloat(promoMinDeposit.toString().replace(/[^0-9.]/g));
        const sanitizedBankMinDeposit = parseFloat(_minDeposit.toString().replace(/[^0-9.]/g));

        const depositValue =
          sanitizedBankMinDeposit >= sanitizedPromoMinDeposit ? sanitizedBankMinDeposit : sanitizedPromoMinDeposit;

        if (_selectedBank?.OverrideCurrencyTopup && _selectedBank?.OverrideCurrencyTopup?.length) {
          setOverrideCurrencyTopup(_selectedBank?.OverrideCurrencyTopup);
        } else setOverrideCurrencyTopup([]);

        if (!isAutoUpdatePromotion) setDepositAmount(depositValue);
      } else {
        setMinimumDeposit('');
        setMaximumDeposit('');
      }
    }
  }, [bankList, isAutoUpdatePromotion, promotions, selectedBank, selectedPromotion]);

  useEffect(() => {
    let valid = true;
    const _errors = {};

    const _selectedPromotion = promotions?.find((f) => f.id === selectedPromotion);
    // promo minimum deposit
    const minDeposit = _selectedPromotion?.min_deposit;

    if (selectedBank) {
      if (!depositAmount) {
        valid = false;
        _errors.depositAmount = translate('x_is_required', { x: `${translate('deposit')} ${translate('amount')}` });
      } else if (parseFloat(depositAmount.toString().replaceAll(',', '')) < parseFloat(minDeposit)) {
        valid = false;
        _errors.depositAmount = translate('minimum_amount_is_x_y', { x: currency, y: minDeposit });
      } else if (
        parseFloat(depositAmount.toString().replaceAll(',', '')) < minimumDeposit ||
        parseFloat(depositAmount.toString().replaceAll(',', '')) > maximumDeposit
      ) {
        // if maximumDeposit === 0 means no min/max deposit
        if (maximumDeposit !== 0) {
          valid = false;
          _errors.depositAmount = translate('deposit_amount_between_x_and_y', { x: minimumDeposit, y: maximumDeposit });
        } else if (parseFloat(depositAmount.toString().replaceAll(',', '')) < minimumDeposit) {
          valid = false;
          _errors.depositAmount = translate('minimum_amount_is_x_y', { x: currency, y: minimumDeposit });
        }
      } else {
        _errors.depositAmount = '';
      }
    }

    setErrors(_errors);
  }, [depositAmount]);

  const filteredPromotions = useMemo(() => {
    if (isAutoUpdatePromotion && promotions?.length && Number(depositAmount || 0) > 0) {
      const _promotions = promotions?.filter(
        (p) =>
          Number(depositAmount) >= Number(p?.min_deposit || 0) &&
          Number(p?.max_deposit === 0 || Number(depositAmount) <= Number(p?.max_deposit))
      );
      return _promotions;
    }
    return promotions;
  }, [depositAmount, isAutoUpdatePromotion, promotions]);

  useEffect(() => {
    if (filteredPromotions?.length) {
      setSelectedPromotion(filteredPromotions[0]?.id);
    } else setSelectedPromotion(0);
  }, [filteredPromotions]);

  const getDepositMethods = () => {
    conObj
      .post('transactions/deposits.json')
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            // success
            const {
              payment_method: paymentMethod,
              promotions,
              CurrencyTopup,
              alert,
              auto_update_promotion_list: autoUpdatePromotionList,
            } = res.data?.data?.data;

            setIsAutoUpdatePromotion(autoUpdatePromotionList || false);

            if (alert) {
              setShowAlert({ show: true, message: alert });
            }

            // set currency topup
            setCurrencyTopup(Object.values(CurrencyTopup));

            if (Object.keys(paymentMethod).length > 0) {
              setPaymentMethods(paymentMethod);

              // setSelectedPaymentMethod(Object.keys(paymentMethod)[0]);
            }

            if (promotions?.length > 0) {
              setPromotions(promotions);
              if (res.data?.data?.data?.auto_select_promotion_id > 0) {
                setSelectedPromotion(promotions?.[0].id);
              } else if (!autoUpdatePromotionList) setSelectedPromotion(promotions[0].id);
            }

            // currency
            setCurrency(process.env.REACT_APP_CURRENCY_CODE);

            // // bank type name
            setBankTypeName(paymentMethod?.bank?.name);
          } else {
            // fail
          }
        }
      })
      .catch((err) => {
        console.log(`Server Error: ${err.message}`);
        const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
        enqueueSnackbar(err.message, snackbarOptions);
      });
  };

  const onSubmit = () => {
    setIsSubmitting(true);

    if (selectedPaymentMethod === 'bank') {
      topupByBankTransfer();
    } else {
      topupByOnlinePayment();
    }
  };

  const handleConfirm = () => {
    if (isValid()) {
      setConfirmDeposit(true);
    }
  };

  const topupByBankTransfer = () => {
    const requestData = {
      'transaction-amount': parseFloat(depositAmount.toString().replaceAll(',', '')),
      // 'transaction-amount': amountInThousandths ? depositAmount / 1000 : depositAmount,
      'transaction-file_base64_1': bankSlip?.file?.base64,
      'transaction-promotion_id': selectedPromotion,
      'transaction-bank_account_id': selectedBank,
      'transaction-bank_type': selectedPaymentMethod,
      /**
       * set 0 in env if don't want reference number
       */
      ...(process.env.REACT_APP_REF_NUMBER_LENGTH > 0 && { 'transaction-reference_no': referenceNumber.trim() }),
    };

    conObj
      .post('transactions/topup.json', requestData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            // success
            // message to show
            // res.data?.data?.msg;
            const snackbarOptions = { variant: 'success', autoHideDuration: 5000 };
            enqueueSnackbar(res.data?.data?.msg, snackbarOptions);
            dispatch(openTransactionsDialog({ open: false, isDeposit: true }));
            setIsSubmitting(false);
            setConfirmDeposit(false);
            navigate('/home');
          }
        }
      })
      .catch((err) => {
        console.log(`Server Error: ${err.message}`);
        setConfirmDeposit(false);
        enqueueSnackbar(err.message, { variant: 'error', autoHideDuration: 5000 });
        setIsSubmitting(false);
      });
  };

  const topupByOnlinePayment = () => {
    if (!selectedPaymentMethod) {
      enqueueSnackbar(translate('please_select_x', { x: translate('payment_method') }), {
        variant: 'error',
        autoHideDuration: 5000,
      });
      setIsSubmitting(false);
      return;
    }

    const requestData = {
      'transaction-amount': parseFloat(depositAmount.toString().replaceAll(',', '')),
      'transaction-promotion_id': selectedPromotion,
      'transaction-bank_account_id': selectedBank,
      'transaction-redirect_url': `${window.location.origin}/payStatus/${selectedPaymentMethod}`,
      // 'transaction-redirect_url': 'https://www.google.com',
    };

    if (selectedBankSource) {
      requestData['transaction-deposit_source_bank_code'] = selectedBankSource;
    }

    if (accountHolder?.name) {
      requestData['transaction-deposit_source_bank_account_holder_name'] = accountHolder?.name;
    }

    if (accountHolder?.number) {
      requestData['transaction-deposit_source_bank_account_number'] = accountHolder?.number;
    }

    conObj
      .post(`${selectedPaymentMethod}/topup_${selectedPaymentMethod}.json`, requestData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            // setIsSubmitting(false);
            if (res.data?.data?.qrCode) {
              setConfirmDeposit(false);
              setTopPayQrCode(res.data?.data?.redirectUrl);
            } else {
              window.location.replace(res.data?.data?.redirectUrl);
            }
            // if (['toppay', 'bitpayz', 'vpay'].includes(selectedPaymentMethod)) {
            //   setConfirmDeposit(false);
            //   setTopPayQrCode(res.data?.data?.redirectUrl);
            // } else {
            //   window.location.replace(res.data?.data?.redirectUrl);
            // }
          }
        }
      })
      .catch((err) => {
        console.log(`Server Error: ${err.message}`);
        setConfirmDeposit(false);
        enqueueSnackbar(err.message, { variant: 'error', autoHideDuration: 5000 });
        setIsSubmitting(false);
      });
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = '';
      // Make new FileReader
      const reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const isValid = () => {
    let valid = true;
    const _errors = {};

    const _selectedPromotion = promotions?.find((f) => f.id === selectedPromotion);
    // promo minimum deposit
    const minDeposit = _selectedPromotion?.min_deposit;

    if (selectedBank) {
      if (!depositAmount) {
        valid = false;
        _errors.depositAmount = translate('x_is_required', { x: `${translate('deposit')} ${translate('amount')}` });
      } else if (parseFloat(depositAmount.toString().replaceAll(',', '')) < parseFloat(minDeposit)) {
        valid = false;
        _errors.depositAmount = translate('minimum_amount_is_x_y', { x: currency, y: minDeposit });
      } else if (
        parseFloat(depositAmount.toString().replaceAll(',', '')) < minimumDeposit ||
        parseFloat(depositAmount.toString().replaceAll(',', '')) > maximumDeposit
      ) {
        // if maximumDeposit === 0 means no min/max deposit
        if (maximumDeposit !== 0) {
          valid = false;
          _errors.depositAmount = translate('deposit_amount_between_x_and_y', { x: minimumDeposit, y: maximumDeposit });
        } else if (parseFloat(depositAmount.toString().replaceAll(',', '')) < minimumDeposit) {
          valid = false;
          _errors.depositAmount = translate('minimum_amount_is_x_y', { x: currency, y: minimumDeposit });
        }
      } else {
        _errors.depositAmount = '';
      }

      if (selectedBankData?.deposit_source_bank_account_holder_name) {
        if (!accountHolder?.name) {
          valid = false;
          _errors.accountHolderName = translate('x_is_required', { x: translate('name') });
        } else {
          _errors.accountHolderName = '';
        }
      }

      if (selectedBankData?.deposit_source_bank_account_number) {
        if (!accountHolder?.number) {
          valid = false;
          _errors.accountHolderNumber = translate('x_is_required', { x: translate('account_number') });
        } else {
          _errors.accountHolderNumber = '';
        }
      }
    }

    // Bank specific validation
    if (selectedPaymentMethod === 'bank') {
      if (!selectedBank) {
        valid = false;
        _errors.selectedBank = translate('please_select_x', { x: translate('bank_account') });
      } else {
        _errors.selectedBank = '';
      }

      if (!bankSlip?.file) {
        valid = false;
        _errors.bankSlip = translate('please_upload_receipt');
      } else {
        _errors.bankSlip = '';
      }

      if (process.env.REACT_APP_REF_NUMBER_LENGTH > 0) {
        if (referenceNumber.trim().length < process.env.REACT_APP_REF_NUMBER_LENGTH) {
          valid = false;
          _errors.referenceNumber = translate('bank_ref_number_validation_x', {
            x: process.env.REACT_APP_REF_NUMBER_LENGTH,
          });
        } else {
          _errors.referenceNumber = '';
        }
      }
    } else if (selectedPaymentMethod !== 'bank') {
      if (!selectedBank && !bankList) {
        valid = false;
        setShowAlert({ show: true, message: translate('please_select_x', { x: translate('payment_method') }) });
      } else if (!selectedBank) {
        valid = false;
        _errors.selectedBank = translate('please_select_x', { x: translate('payment_method') });
      } else {
        _errors.selectedBank = '';
      }
    }

    setErrors(_errors);
    return valid;
  };

  const toggleDrawer = (open) => {
    setConfirmDeposit(open);
  };

  const handleFileDrop = (acceptedFiles, mediaType) => {
    const file = acceptedFiles[0];

    if (file) {
      const url = {
        preview: URL.createObjectURL(file),
      };
      getBase64(file)
        .then((result) => {
          file.base64 = result;
          setBankSlip({ file, filePreview: url.preview });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getPromotion = (amount) => {
    const amountFloat = parseFloat(amount.toString().replaceAll(',', ''));
    const _selectedPromotion = promotions?.find((f) => f.id === selectedPromotion);

    let bonus = 0;
    if (_selectedPromotion.bonus_type === 'percent') {
      bonus = (_selectedPromotion.bonus / 100) * amountFloat;
    } else {
      bonus = _selectedPromotion.bonus;
    }

    const maxBonusAmount = _selectedPromotion?.max_bonus_amount;

    /**
     * if max_bonus_amount === 0 - no cap
     * else, set cap
     */
    if (parseFloat(maxBonusAmount) > 0 && bonus > parseFloat(maxBonusAmount)) {
      bonus = _selectedPromotion?.max_bonus_amount;
    }

    const minDeposit = _selectedPromotion?.min_deposit;

    /**
     * bonus only apply if deposit amount => minDeposit
     */
    if (amountFloat < parseFloat(minDeposit)) {
      bonus = 0;
    }

    return fCurrency(bonus);
  };

  const makeCurrencyTopup = (amount, index) => {
    const _amount = amount;
    // const _amount = amountInThousandths ? amount * 1000 : amount;

    return (
      <Grid item xl={4} lg={4} md={4} sm={4} xs={4} key={index}>
        <SuggestedTopupBox onClick={() => setDepositAmount(_amount)} isActive={depositAmount === _amount}>
          {selectedPromotion.toString() !== '0' && (
            <SmallPromoBox component="span" isActive={depositAmount === _amount}>
              <span>+</span>
              {/* <span>{currency}</span> */}
              <span>{`${getPromotion(_amount)}`}</span>
            </SmallPromoBox>
          )}
          <SuggestedTopupTxt>{`${fCurrency(_amount)}`}</SuggestedTopupTxt>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              position: 'absolute',
              bottom: '-2px',
              color: depositAmount === _amount ? '#D9D0F1' : '#545167',
              height: '16px',
              right: '-2px',
              width: '17px',
              zIndex: 1,
            }}
          >
            <path d="M17 0L0 16H12C14.7614 16 17 13.7614 17 11V0Z" fill="currentColor" />
            <path
              d="M9.35493 11.6942L11.1083 12.8822L13.6758 9.00007"
              stroke={depositAmount === _amount ? '#000000' : '#ffffff'}
              strokeWidth="0.822792"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </SuggestedTopupBox>
      </Grid>
    );
  };

  const getDisplayDepositBonus = () => {
    const _selectedPromotion = promotions?.find((f) => f.id === selectedPromotion);

    const depositAmountFloat = parseFloat(depositAmount.toString().replaceAll(',', ''));

    const bonusType =
      _selectedPromotion.bonus_type === 'percent'
        ? `${_selectedPromotion.bonus}%`
        : `${currency}${_selectedPromotion.bonus}`;

    let bonus = 0;

    if (_selectedPromotion.bonus_type === 'percent') {
      bonus = (_selectedPromotion.bonus / 100) * depositAmountFloat;
    } else {
      bonus = _selectedPromotion.bonus;
    }

    const maxBonusAmount = _selectedPromotion?.max_bonus_amount;
    /**
     * if max_bonus_amount === 0 - no cap
     * else, set cap
     */
    if (parseFloat(maxBonusAmount) > 0 && bonus > parseFloat(maxBonusAmount)) {
      bonus = _selectedPromotion?.max_bonus_amount;
    }

    const minDeposit = _selectedPromotion?.min_deposit;
    /**
     * bonus only apply if deposit amount => minDeposit
     */
    if (parseFloat(depositAmountFloat) < parseFloat(minDeposit)) {
      bonus = 0;
    }

    return (
      <BonusBox container>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
          <Typography sx={{ fontWeight: 300 }}>{translate('deposit_bonus')}</Typography>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
          <Typography sx={{ fontWeight: 'inherit', textAlign: 'end' }}>{`${bonusType}`}</Typography>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12} sx={{ wordBreak: 'break-all' }}>
          <Typography sx={{ fontWeight: 'inherit', textAlign: 'end' }}>{`+${currency} ${fCurrency(bonus)}`}</Typography>
        </Grid>
      </BonusBox>
    );
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar(`${translate('copied')}!`);
  };

  return (
    <>
      <Box mt={1}>
        {showAlert.show && (
          <Box
            className="alert-pulse"
            sx={{ backgroundColor: 'rgb(126 0 0 / 70%)', p: 2, position: 'relative', mb: 1 }}
          >
            <Stack direction="row" alignItems={'center'}>
              <Typography sx={{ fontSize: '12px', fontWeight: 700, color: 'white', flexGrow: 1 }}>
                {showAlert.message}
              </Typography>
              <Iconify
                icon={'ep:close'}
                width={24}
                height={24}
                sx={{ cursor: 'pointer' }}
                onClick={() => setShowAlert({ show: false, message: '' })}
              />
            </Stack>
          </Box>
        )}

        <Box>
          <TypographyCustom sx={{ fontWeight: 700 }}>{translate('choose_deposit_method')}</TypographyCustom>
          <PayMethodWrapper isActive={!!selectedPaymentMethod}>
            {Object.keys(paymentMethods)?.length > 0 && (
              <FormControl sx={{ width: '100%', p: 0.5 }}>
                <RadioGroup
                  value={selectedPaymentMethod}
                  onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                  sx={{ width: '100%' }}
                >
                  {Object.keys(paymentMethods).map((key, idx) => (
                    <Box key={key} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Iconify
                        icon={key === 'bank' ? 'ic:baseline-account-balance' : 'mdi:credit-card-wireless-outline'}
                        sx={{ fontSize: '17px' }}
                      />
                      <FormControlLabel
                        value={key}
                        control={<Radio />}
                        label={translate(paymentMethods?.[key]?.name)}
                        labelPlacement="start"
                        sx={{ justifyContent: 'space-between', marginRight: 'unset', flexGrow: 1 }}
                        componentsProps={{
                          typography: {
                            fontSize: '14px',
                            fontWeight: 700,
                            color: 'white',
                          },
                        }}
                      />
                    </Box>
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          </PayMethodWrapper>
        </Box>

        {selectedPaymentMethod && (
          <>
            {bankList && (
              <Box>
                <Stack direction="row" sx={{ mt: 1 }}>
                  <span style={{ color: 'red', marginRight: '4px' }}>*</span>
                  <TypographyCustom>
                    {selectedPaymentMethod === 'bank' ? translate('transfer_to') : translate('payment_method')}{' '}
                  </TypographyCustom>
                </Stack>
                <StyledSelect
                  value={selectedBank}
                  onChange={(e) => setSelectedBank(e.target.value)}
                  disableUnderline={true}
                >
                  {bankList.map((item, index) => (
                    <option key={index} value={item?.BankAccount?.id}>
                      {item?.BankAccount?.label}
                    </option>
                  ))}
                </StyledSelect>
                {errors?.selectedBank && <span style={{ fontSize: '12px', color: 'red' }}>{errors?.selectedBank}</span>}
              </Box>
            )}

            {Object.keys(bankSourceList)?.length ? (
              <Box>
                <Stack direction="row" sx={{ mt: 1, alignItems: 'end' }}>
                  <span style={{ color: 'red', marginRight: '4px' }}>*</span>
                  <TypographyCustom>{translate('deposit_from_bank')}</TypographyCustom>
                </Stack>
                <StyledSelect
                  value={selectedBankSource}
                  onChange={(e) => setSelectedBankSource(e.target.value)}
                  disableUnderline
                >
                  {Object.keys(bankSourceList).map((key, index) => (
                    <option key={index} value={key}>
                      {bankSourceList[key]}
                    </option>
                  ))}
                </StyledSelect>
                {errors?.selectedBank && (
                  <span style={{ fontSize: '12px', color: '#ffa244', fontWeight: 600 }}>{errors?.selectedBank}</span>
                )}
              </Box>
            ) : (
              ''
            )}

            {selectedBank && (
              <>
                {!selectedHasCustomInput && (
                  <SelectedBankBox>
                    <Box sx={{ display: 'flex', float: 'right' }}>
                      {selectedPaymentMethod === 'bank' && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            copyToClipboard(
                              bankList.find((f) => f.BankAccount.id === selectedBank)?.BankAccount?.account_number
                            )
                          }
                          color="primary"
                          sx={{ p: 0 }}
                        >
                          <Iconify icon={'ph:copy'} />
                        </IconButton>
                      )}
                      {bankList.find((f) => f.BankAccount.id === selectedBank)?.BankAccount?.qrcode_photo_url ? (
                        <IconButton
                          size="small"
                          onClick={() =>
                            setShowBankQrCode(bankList.find((f) => f.BankAccount.id === selectedBank)?.BankAccount)
                          }
                          color="primary"
                          sx={{ p: 0 }}
                        >
                          <Iconify icon={'ic:baseline-qrcode'} />
                        </IconButton>
                      ) : (
                        ''
                      )}
                    </Box>
                    <Box>
                      {selectedPaymentMethod === 'bank' && (
                        <SmallText>
                          <SmallTextBold>{`${translate('bank_name')}: `}</SmallTextBold>
                          {bankList.find((f) => f.BankAccount.id === selectedBank)?.Bank?.name}
                        </SmallText>
                      )}
                      <SmallText>
                        <SmallTextBold>{`${translate('account_name')}: `}</SmallTextBold>
                        {bankList.find((f) => f.BankAccount.id === selectedBank)?.BankAccount?.account_name}
                      </SmallText>
                      <SmallText>
                        <SmallTextBold>{`${translate('account_number')}: `}</SmallTextBold>
                        {bankList.find((f) => f.BankAccount.id === selectedBank)?.BankAccount?.account_number}
                      </SmallText>
                    </Box>
                  </SelectedBankBox>
                )}

                {selectedHasCustomInput ? (
                  <Box sx={{ mb: '20px', mt: 1 }}>
                    {selectedBankData?.deposit_source_bank_account_holder_name ? (
                      <>
                        <Stack direction="row">
                          <span style={{ color: 'red', marginRight: '4px' }}>*</span>
                          <TypographyCustom>{translate('account_holder_name')}</TypographyCustom>
                        </Stack>
                        <DepositInputBox direction={'column'} flex={1} sx={{ my: 0 }}>
                          <Stack direction="row" alignItems={'center'} flex={1} sx={{ width: '100%' }}>
                            <Stack direction="row" flex={1}>
                              <StyledInput
                                value={accountHolder?.name || ''}
                                onChange={(e) => setAccountHolder((prev) => ({ ...prev, name: e.target.value }))}
                                // inputProps={{ style: { textAlign: 'unset' } }}
                                // sx={{ fontSize: '14px' }}
                                autoFocus
                              />
                            </Stack>
                          </Stack>
                        </DepositInputBox>
                        {errors?.accountHolderName && (
                          <span style={{ fontSize: '12px', color: '#ffa244' }}>{errors?.accountHolderName}</span>
                        )}
                      </>
                    ) : (
                      ''
                    )}

                    {selectedBankData?.deposit_source_bank_account_number ? (
                      <>
                        <Stack direction="row">
                          <span style={{ color: 'red', marginRight: '4px' }}>*</span>
                          <TypographyCustom>{translate('account_number')}</TypographyCustom>
                        </Stack>
                        <DepositInputBox direction={'column'} flex={1} sx={{ my: 0 }}>
                          <Stack direction="row" alignItems={'center'} flex={1} sx={{ width: '100%' }}>
                            <Stack direction="row" flex={1}>
                              <StyledInput
                                aria-describedby="outlined-weight-helper-text"
                                value={accountHolder?.number || ''}
                                onChange={(e) => setAccountHolder((prev) => ({ ...prev, number: e.target.value }))}
                              />
                            </Stack>
                          </Stack>
                        </DepositInputBox>
                        {errors?.accountHolderNumber && (
                          <span style={{ fontSize: '12px', color: '#ffa244' }}>{errors?.accountHolderNumber}</span>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </Box>
                ) : (
                  ''
                )}
              </>
            )}

            <Stack direction="row" sx={{ mt: 2 }} justifyContent={'space-between'} alignItems={'center'}>
              <Stack direction="row">
                <span style={{ color: 'red', marginRight: '4px' }}>*</span>
                <TypographyCustom>{translate('deposit')}</TypographyCustom>
              </Stack>
              <TypographyCustom>{translate('amount')}</TypographyCustom>
            </Stack>
            <DepositInputBox direction={'column'} flex={1} hasData={selectedPromotion.toString() !== '0'}>
              <Stack direction="row" alignItems={'center'} flex={1}>
                <Stack flex={1}>
                  <LargeBlackText>{currency}</LargeBlackText>
                </Stack>
                <DepositDivider orientation="vertical" flexItem />
                <Stack direction="row" flex={1.5} justifyContent={'end'}>
                  <DepositInput
                    id="outlined-adornment-weight"
                    // endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    value={fNumberCommaSeparated(depositAmount)}
                    onChange={(e) => setDepositAmount(e.target.value?.slice(0, 13))}
                    autoFocus
                  />
                </Stack>
              </Stack>
            </DepositInputBox>

            {selectedPromotion.toString() !== '0' && (
              <Container sx={{ backgroundColor: '#1F0059', borderRadius: '0 0 6px 6px', padding: '5px 11px' }}>
                <Stack
                  direction={{ sm: 'column', xs: 'row' }}
                  sx={{ width: { sm: 'unset', xs: '100%' } }}
                  justifyContent={'end'}
                >
                  <ExtraText>{translate('extra')}</ExtraText>
                  <ExtraText>{`+${currency}${getPromotion(depositAmount)}`}</ExtraText>
                </Stack>
              </Container>
            )}

            {errors?.depositAmount && <span style={{ fontSize: '12px', color: 'red' }}>{errors?.depositAmount}</span>}

            <Box sx={{ mt: '10px' }}>
              <Grid container spacing={1}>
                {(overrideCurrencyTopup.length > 0 ? overrideCurrencyTopup : currencyTopup).map((item, index) =>
                  makeCurrencyTopup(item, index)
                )}
              </Grid>

              {/* {promotions && (
          <Box>
            <Stack direction="row" sx={{ mt: 2 }}>
              <span style={{ color: 'red', marginRight: '4px' }}>*</span>
              <TypographyCustom>{translate('select_bonus')}</TypographyCustom>
            </Stack>

            <StyledSelect
              value={selectedPromotion}
              onChange={(e) => setSelectedPromotion(e.target.value)}
              disableUnderline={true}
            >
              <option value={0}>{translate('no_promotion')}</option>
              {promotions?.map((item, index) => (
                <option key={index} value={item?.id}>
                  {item?.title}
                </option>
              ))}
            </StyledSelect>
          </Box>
        )} */}
              {filteredPromotions && (
                <Box>
                  <Stack direction="row" sx={{ mt: 2 }}>
                    <span style={{ color: 'red', marginRight: '4px' }}>*</span>
                    <TypographyCustom sx={{ fontWeight: 700 }}>{translate('select_bonus')}</TypographyCustom>
                  </Stack>
                  <StyledSelect
                    value={selectedPromotion}
                    onChange={(e) => setSelectedPromotion(e.target.value)}
                    disableUnderline={true}
                  >
                    <option value={0}>{translate('no_promotion')}</option>
                    {filteredPromotions?.map((item, index) => (
                      <option key={index} value={item?.id}>
                        {item?.title}
                      </option>
                    ))}
                  </StyledSelect>
                </Box>
              )}
              {selectedPromotion.toString() !== '0' && getDisplayDepositBonus()}
            </Box>

            {selectedPaymentMethod === 'bank' && selectedBank && (
              <Box sx={{ mt: 2 }}>
                <Stack direction="row">
                  <span style={{ color: 'red', marginRight: '4px' }}>*</span>
                  <TypographyCustom>{translate('upload_receipt')}:</TypographyCustom>
                </Stack>
                <UploadSingleFile
                  accept="image/*"
                  // files={bankSlip.value}
                  // error={checkError}
                  onDrop={(files) => handleFileDrop(files, 'bankSlip')}
                  // helperText={
                  //   checkError && (
                  //     <FormHelperText error sx={{ px: 2 }}>
                  //       {error?.message}
                  //     </FormHelperText>
                  //   )
                  // }
                  // {...other}
                />
                {errors?.bankSlip && <span style={{ fontSize: '12px', color: 'red' }}>{errors?.bankSlip}</span>}
                {bankSlip && (
                  <Box>
                    <img
                      src={bankSlip?.filePreview}
                      style={{ maxWidth: '100%', mt: 1, mb: 2, maxHeight: '180px' }}
                      alt="Preview Bank Slip"
                    />
                  </Box>
                )}
                {process.env.REACT_APP_REF_NUMBER_LENGTH > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Stack direction="row">
                      <span style={{ color: 'red', marginRight: '4px' }}>*</span>
                      <TypographyCustom>
                        {translate('last_x_digit_bank_ref_number', { x: process.env.REACT_APP_REF_NUMBER_LENGTH })}:
                      </TypographyCustom>
                    </Stack>
                    <BankRefNumber
                      variant="outlined"
                      value={referenceNumber}
                      onChange={(e) => setReferenceNumber(e.target.value)}
                      placeholder={translate('last_x_digit_bank_ref_number', {
                        x: process.env.REACT_APP_REF_NUMBER_LENGTH,
                      })}
                    />
                    {errors?.referenceNumber && (
                      <span style={{ fontSize: '12px', color: 'red' }}>{errors?.referenceNumber}</span>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </>
        )}

        <ConfirmButtonContainer container spacing={1}>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { sm: 'unset', xs: 'center' },
            }}
          >
            <Button
              variant="outlined"
              fullWidth
              sx={{ color: '#D9D0F1', borderColor: '#D9D0F1' }}
              onClick={() => dispatch(openTransactionsDialog({ open: false, isDeposit: true }))}
            >
              {translate('cancel')}
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: 'flex', justifyContent: { xl: 'end', lg: 'end', md: 'end', sm: 'end', xs: 'center' } }}
          >
            <ThemeButton fullWidth disabled={!selectedPaymentMethod} onClick={() => handleConfirm()}>
              {translate('confirm')}
            </ThemeButton>
            {/* <Stack direction="row" sx={{ alignItems: 'center' }}>
                  <DepositBtnAmount>{`${currency} ${fCurrency(depositAmount)}`}</DepositBtnAmount>
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right-circle"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                    style={{ color: 'white' }}
                  >
                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm154.7 454.5l-246 178c-5.3 3.8-12.7 0-12.7-6.5v-46.9c0-10.2 4.9-19.9 13.2-25.9L566.6 512 421.2 406.8c-8.3-6-13.2-15.6-13.2-25.9V334c0-6.5 7.4-10.3 12.7-6.5l246 178c4.4 3.2 4.4 9.8 0 13z" />
                  </svg>
                </Stack> */}
          </Grid>
        </ConfirmButtonContainer>

        {confirmDeposit && (
          <ConfirmDepositDrawer
            confirmDeposit={confirmDeposit}
            toggleDrawer={toggleDrawer}
            depositAmount={fCurrency(depositAmount)}
            extraAmount={selectedPromotion !== '0' && selectedPromotion !== 0 ? getPromotion(depositAmount) : 0}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        )}

        <CustomModal maxWidth="xs" open={!!showBankQrCode} onClose={() => setShowBankQrCode(null)}>
          <Stack sx={{ py: 5, px: 4, alignItems: 'center' }} spacing={2}>
            <Card sx={{ bgColor: 'white' }}>
              <Image src={showBankQrCode?.qrcode_photo_url} />
            </Card>

            <Typography textAlign={'center'}>{showBankQrCode?.label}</Typography>
          </Stack>
        </CustomModal>

        <PopupQrCode qrCodeString={topPayQrCode} setTopPayQrCode={setTopPayQrCode} />
      </Box>
    </>
  );
};

export default Deposit;
